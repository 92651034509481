import React from 'react';
import { graphql } from 'gatsby';

import Hero from '../components/hero';
import InfoRows from '../components/InfoRows';
import CTAColumns from '../components/cta-columns';
import Pricing from '../components/pricing';

import GraphQLErrorList from '../components/graphql-error-list';
import SEO from '../components/seo';
import Layout from '../containers/layout';
import { localize } from '../lib/helpers';
import LogoRow from '../components/logoRow';
import CTASection from '../components/cta-section';
import { Map } from '../components/maps';

export const query = graphql`
  query CustomPageTemplateQuery($id: String!) {
    page: sanityPage(_id: { eq: $id }) {
      ...PageInfo
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      primaryColor {
        hex
      }
      secondaryColor {
        hex
      }
      title
      openGraph {
        title
        description
        image {
          ...SanityImage
        }
      }
    }
  }
`;

const Page = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const { site, page } = localize(data || {}, ['nl']);

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.',
    );
  }

  const content = (page._rawContent || [])
    .filter((c) => !c.disabled)
    .map((c, i) => {
      let el = null;
      switch (c._type) {
        case 'pricing':
          el = <Pricing key={c._key} {...c} />;
          break;
        case 'infoRows':
          el = <InfoRows key={c._key} {...c} />;
          break;
        case 'hero':
          el = <Hero key={c._key} {...c} />;
          break;
        case 'ctaColumns':
          el = <CTAColumns key={c._key} {...c} />;
          break;
        case 'ctaPlug':
          el = <CTASection key={c._key} {...c} />;
          break;
        case 'uiComponentRef':
          switch (c.name) {
            case 'logo':
              el = <LogoRow key={c._key} />;
              break;
            case 'map':
              el = (
                <section key={c._key} className="lg:container md:flex lg:m-auto relative py-8">
                  <Map />
                </section>
              );
              break;
            default:
              break;
          }
          break;
        default:
          el = null;
      }
      return el;
    });

  const menuItems = page.navMenu && (page.navMenu.items || []);
  const pageTitle = page.title;

  return (
    <Layout navMenuItems={menuItems} textWhite={true}>
      <SEO
        title={pageTitle}
        description={site.description}
        keywords={site.keywords}
        bodyAttr={{
          class: 'leading-normal tracking-normal text-black font-josefinslab',
        }}
      />
      <div className="pt-24 flex flex-col">{content}</div>
    </Layout>
  );
};

export default Page;
